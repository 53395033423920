<template>
  <div>
    <ui-page-hero title="Channel" />

    <div class="columns">
      <div class="column">
        <div>
          <div
            v-for="(meetingtype, index) in channel.Meetingtypes"
            :key="index"
            class="box"
          >
            <div class="subtitle">
              {{ meetingtype.MeetingtypeId | getMeetingtypeName }}
            </div>
            <div class="chartBar">
              <TransactionActivity :meetingtypeId="meetingtype.MeetingtypeId" />
            </div>
          </div>

          <ui-section-header>
            <template v-slot:title>Fee invoice revenue</template>
          </ui-section-header>
          <StatsMonthYear
            :type="2"
            :channelId="channel.Id"
            :showSelect="false"
          />

          <ui-section-header>
            <template v-slot:title>MS seats booked</template>
          </ui-section-header>
          <StatsMonthYear
            :type="7"
            :channelId="channel.Id"
            :showSelect="false"
          />

          <div class="card">
            <StatsMonthYear :channelId="channel.Id" :showSelect="true" />
          </div>
        </div>
      </div>

      <div class="column is-one-third">
        <LocationsAwaitingApproval class="pb-6" />
        <LocationsSubscriptionCanceled class="pb-6" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const TransactionActivity = () =>
  import('@/components/Dashboards/TransactionActivity')

export default {
  components: {
    TransactionActivity,
    LocationsAwaitingApproval: () =>
      import('@/components/Dashboards/LocationsAwaitingApproval'),
      LocationsSubscriptionCanceled: () => import('@/components/Dashboards/LocationsSubscriptionCanceled'),
    StatsMonthYear: () => import('@/components/Dashboards/StatsMonthYear'),
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  methods: {},
}
</script>

<style lang="scss" scoped></style>
